<template>
	<v-container fluid class="min-height-100vh">

		<the-top-logo></the-top-logo>

		<the-hero-section></the-hero-section>

		<the-sub-hero-section></the-sub-hero-section>

		<the-line-seperator></the-line-seperator>

		<the-compass-section></the-compass-section>
		
		<the-line-seperator class="my-drop-shadow"></the-line-seperator>

		<the-infographic-section></the-infographic-section>

		<the-footer-section></the-footer-section>

	</v-container>
</template>

<script>
import TheTopLogo from '@/components/landing_pages/compass_to_your_money/TheTopLogo.vue';
import TheHeroSection from '@/components/landing_pages/compass_to_your_money/TheHeroSection.vue';
import TheSubHeroSection from '@/components/landing_pages/compass_to_your_money/TheSubHeroSection.vue';
import TheLineSeperator from '@/components/landing_pages/compass_to_your_money/TheLineSeperator.vue';
import TheCompassSection from '@/components/landing_pages/compass_to_your_money/TheCompassSection.vue';
import TheInfographicSection from '@/components/landing_pages/compass_to_your_money/TheInfographicSection.vue';
import TheFooterSection from '@/components/landing_pages/compass_to_your_money/TheFooterSection.vue';

export default {
	components: {
		TheTopLogo,
		TheHeroSection,
		TheSubHeroSection,
		TheLineSeperator,
		TheCompassSection,
		TheInfographicSection,
		TheFooterSection,
	},
	mounted() {
		document.title = 'כספן - איתור כספים אבודים';
	},
}
</script>

<style scoped>

	.my-drop-shadow {
		box-shadow: 0px 25px 35px 0px rgba(0,0,0,0.39);
	}

</style>