<template>
	<v-row>
		<v-col cols="12" class="pa-0">
			<v-img min-height="450">
				<div class="fill-height bg-gradient d-flex flex-column">

					<v-container fluid>
						<v-row class="pt-5 pb-10 py-md-15 px-5 px-md-0">
							<v-col cols="0" md="3"></v-col>
							<v-col cols="12" md="5" class="d-flex flex-column flex-md-row">
								
								<div class="d-flex flex-column align-md-start align-center text-center text-md-right white--text">
									<h4 class="text-lg-h4 text-h5">ל-8 מתוך 10 ישראלים יש כסף אבוד שלא ידעו על קיומו!</h4>

									<h2 ref="fifteenBil" class="text-lg-h2 text-h4 font-weight-bold mt-8">מעל
										<br v-if="$vuetify.breakpoint.smAndDown">
										<span class="mr-2">&#8362;</span>{{ commaSeperatedNumber }}
									</h2>

									<h4 class="text-lg-h4 text-h5 font-weight-bold mt-5">שוכבים בחשבונות</h4>
									<h4 class="text-lg-h4 text-h5 font-weight-bold">רדומים בחברות הביטוח ובבתי ההשקעות</h4>

									<v-btn
										class="white--text mt-10 px-12 py-8 text-h4 rounded-pill"
										elevation="8"
										x-large
										color="ctym-grey"
										:to="{name: routeNames.Wizard, query: {...$route.query}, params: { refPage: landingPagesNames.compassToYourMoney }}"
									>
										התחל בדיקה
									</v-btn>
								</div>
								
							</v-col>
							<v-col cols="12" md="4" class="mt-10 mt-md-0">
								<div style="max-width:320px !important; height:335px; !important" class="mx-auto mx-md-0">
									<v-img :src="coinsImage" alt="Stack of coins" class="my-responsive-img" eager></v-img>
								</div>
							</v-col>
						</v-row>
					</v-container>
					
				</div>
			</v-img>
		</v-col>
	</v-row>
</template>

<script>
import coinsImage from '@/assets/media/imgs/ctym_hero_coins.png';
import * as routeNames from '@/router/route-names';
import { landingPagesNames } from '@/config/wizard.config';

export default {
	data: () => ({
		routeNames,
		landingPagesNames,
		coinsImage,
		numberInterval: null,
		fifteenBilNum: 1111111111, // 1,111,111,111
	}),
	computed: {
		commaSeperatedNumber() {
			return this.fifteenBilNum.toLocaleString("en-US");
		},
	},
	mounted() {
		this.animateCountUpNumber();
	},
	methods: {
		animateCountUpNumber() {
			this.numberInterval = window.setInterval(() => {

				if (this.fifteenBilNum >= 15000000000) {
					clearInterval(this.numberInterval);
					this.fifteenBilNum = 15000000000;
					this.$refs.fifteenBil.classList.add('bonk-text');
					return;
				}

				this.fifteenBilNum += 87654321;

			}, 5);
		},
	},
}
</script>

<style scoped>

	.bg-gradient {
		background: radial-gradient(circle, var(--v-ctym-lightblue-base) 0%, var(--v-ctym-blue-base) 100%);
	}

	@keyframes bonk {
		0% { transform: scale(1) }
		50% { transform: scale(1.2) }
		100% { transform: scale(1) }
	}

	.bonk-text {
		animation: bonk 700ms ease-out;
	}

</style>
