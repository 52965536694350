<template>
	<v-row>
		<v-col cols="12" style="max-height:770px">

			<div class="my-compass-wrapper mx-auto mt-10 mb-15">
				<v-img :src="compassGif" alt="The compass to your money" class="my-responsive-img" eager></v-img>
			</div>

			<div class="my-article-wrapper mx-auto">
				<v-img v-if="$vuetify.breakpoint.smAndUp" :src="articleDesktop" alt="Ynet article about lost money" class="my-responsive-img" eager></v-img>
				<v-img v-else :src="articleMobile" alt="Ynet article about lost money" class="my-responsive-img" eager></v-img>
			</div>

		</v-col>
	</v-row>
</template>

<script>
import compassGif from '@/assets/media/imgs/ctym_compass.gif';
import articleDesktop from '@/assets/media/imgs/ctym_article_desktop.png';
import articleMobile from '@/assets/media/imgs/ctym_article_mobile.png';


export default {
	data: () => ({
		compassGif,
		articleDesktop,
		articleMobile
	}),
}
</script>


<style scoped>

	.my-compass-wrapper {
		max-width: 600px;
		max-height: 400px;
	}

	.my-article-wrapper {
		max-width: 1000px;
		max-height: 600px;
	}

</style>