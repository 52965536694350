<template>
	<v-row>
		<v-col cols="12" class="pa-0">
			<div class="my-line"></div>
		</v-col>
	</v-row>
</template>

<script>
export default {

}
</script>

<style scoped>

	.my-line {
		background: radial-gradient(circle, var(--v-ctym-lightblue-base) 0%, var(--v-ctym-blue-base) 100%);
		height: 10px;
	}

</style>