<template>
	<v-row>
		<v-col cols="12" class="pa-0">
			<v-img>
				<div class="fill-height bg-gradient d-flex flex-column">

					<div class="py-15">
						<div class="d-flex flex-column flex-md-row justify-center align-center px-5 px-sm-15 mx-md-0">

							<div class="d-flex flex-column align-md-start align-center text-center text-md-right mt-md-10 layer-over-image">
								<h4 class="text-md-h3 text-h4  white--text">אל תשאירו</h4>
								<h4 class="text-md-h3 text-h4  white--text">כסף אבוד מאחור</h4>
								<h4 class="text-md-h3 text-h4 font-weight-bold ctym-grey--text">המצפן לכסף שלך</h4>

								<v-btn
									class="white--text mt-5 px-md-10 py-md-8 text-h4 rounded-pill"
									elevation="8"
									x-large
									color="ctym-grey"
									:to="{name: routeNames.Wizard, query: {...$route.query}, params: { refPage: landingPagesNames.compassToYourMoney }}"
								>
									התחל בדיקה
									<v-icon color="white" x-large>mdi-chevron-left</v-icon>
								</v-btn>
								<small class="white--text text-body-1 mt-2 mr-md-7">מילוי השאלון אורך כ90 שניות</small>
							</div>

							<div class="coin-jar-wrapper">
								<v-img :src="coinsImage" alt="Jar of coins" class="my-responsive-img mt-10 mt-md-0" eager></v-img>
							</div>

						</div>
					</div>
		
				</div>
			</v-img>
		</v-col>
	</v-row>
</template>

<script>
import coinsImage from '@/assets/media/imgs/ctym_coins_jar.png';
import * as routeNames from '@/router/route-names';
import { landingPagesNames } from '@/config/wizard.config';

export default {
	data: () => ({
		routeNames,
		landingPagesNames,
		coinsImage,
	}),

}
</script>

<style scoped>

	.layer-over-image {
		z-index: 2 !important;
	}

	.bg-gradient {
		background: radial-gradient(circle, var(--v-ctym-lightblue-base) 0%, var(--v-ctym-blue-base) 100%);
	}

	.coin-jar-wrapper {
		margin-right: -10%;
		width: auto;
	}

	@media screen and (max-width: 960px) {

		.coin-jar-wrapper {
			margin-right: 0;
			width: 100%;
		}
    
	}

</style>
