<template>
	<v-row>
		<v-col cols="12">

			<h3 class="ctym-midblue--text text-center text-h4 text-md-h2 font-weight-bold mt-5">תהליך איתור הכספים</h3>

			<div class="mt-16 d-flex flex-column align-center ctym-midblue--text fill-height">


				<!-- Infographic 1 -->
				<v-lazy v-model="infographicOneLazy" min-height="400" :options="{ threshold: 1 }">
					<div class="d-flex flex-column flex-md-row align-center pb-15 py-md-10">

						<div class="my-text-wrapper mx-md-16 text-center">
							<p class="mb-0 text-md-h4 text-h5">
								<span class="text-md-h1 text-h2">1</span>
								מילוי שאלון דיגיטלי קצר
								<br>
								וחתימה על ייפוי כח
							</p>
							<p class="text-md-h5 text-h6">(90 שניות בלבד)</p>
						</div>

						<div class="img-wrapper px-10 px-md-0">
							<v-img :src="infographic_one" alt="Infographic one" class="my-responsive-img mt-10 mt-md-0 mx-auto mx-md-16" eager></v-img>
						</div>

					</div>
				</v-lazy>
				<!-- Infographic 1 -->
				
				<!-- Infographic 2 -->
				<v-lazy v-model="infographicTwoLazy" min-height="400" :options="{ threshold: 1 }">
					<div class="d-flex flex-column-reverse flex-md-row align-center my-15 my-md-10">

						<div class="img-wrapper px-10 px-md-0">
							<v-img :src="infographic_two" alt="Infographic one" class="my-responsive-img mt-10 mt-md-0 mx-auto mx-md-16" eager></v-img>
						</div>

						<div class="my-text-wrapper mx-md-16 text-center">
							<p class="mb-0 text-md-h4 text-h5">
								<span class="text-md-h1 text-h2">2</span>
								מערכת כספן
								<br>
								תחפש עבורך את הכספים
								<br>
								ביותר מ 100 גופים פיננסים שונים
							</p>
						</div>
						
					</div>
				</v-lazy>
				<!-- Infographic 2 -->

				<!-- Infographic 3 -->
				<v-lazy v-model="infographicThreeLazy" min-height="400" :options="{ threshold: 1 }">
					<div class="d-flex flex-column flex-md-row align-center my-15 my-md-10">

						<div class="my-text-wrapper mx-md-16 text-center">
							<p class="mb-0 text-md-h4 text-h5">
								<span class="text-md-h1 text-h2">3</span>
								פגישה טלפונית\פרונטלית
								<br>
								עם בעל רשיון פנסיוני
								<br>
								לסקירת הממצאים
							</p>
						</div>

						<div class="img-wrapper px-10 px-md-0">
							<v-img :src="infographic_three" alt="Infographic one" class="my-responsive-img mt-10 mt-md-0 mx-auto mx-md-16" eager></v-img>
						</div>

					</div>
				</v-lazy>
				<!-- Infographic 3 -->

			</div>

		</v-col>
	</v-row>
</template>

<script>
import infographic_one from '@/assets/media/imgs/ctym_infographic1.png';
import infographic_two from '@/assets/media/imgs/ctym_infographic2.png';
import infographic_three from '@/assets/media/imgs/ctym_infographic3.png';

export default {
	data: () => ({
		infographic_one,
		infographic_two,
		infographic_three,
		infographicOneLazy: false,
		infographicTwoLazy: false,
		infographicThreeLazy: false,
	}),
}
</script>

<style scoped>

	.my-text-wrapper {
		max-width: 450px;
	}

	.img-wrapper {
		max-width: 100vw;
	}

</style>