<template>
	<v-row class="py-3 py-md-5 px-15 px-md-0">
		<v-col cols="0" md="3"></v-col>
		<v-col cols="12" md="9" style="height:150px;">
			<img :src="logo" alt="Caspen brand logo" class="my-responsive-img d-block mx-auto mx-md-0" rel="preload">
		</v-col>
	</v-row>
</template>

<script>
import logo from '@/assets/media/imgs/ctym_top_logo.png';

export default {
	data: () => ({
		logo,
	}),
}
</script>