<template>
	<v-row class="pt-3 pt-md-10 px-2 px-md-0">

		<v-col cols="0" md="3"></v-col>

		<v-col cols="12" md="5">
			<v-lazy v-model="textLazy" min-height="500" :options="{ threshold: .6 }">
				<div class="d-flex flex-column align-md-start align-center">

					<h2 class="text-lg-h2 text-h4 font-weight-bold small-title-gradient mr-md-13 text-center text-md-right">איך נוצר כסף אבוד?</h2>

					<div class="mt-5 px-0 px-sm-15 px-md-0">
						<div
							class="d-flex mt-7 align-center"
							v-for="(item, i) in bullets"
							:key="i"
						>
							<v-img :src="coinImage" alt="A little coin bullet point" class="my-responsive-img small-size-coin elevation-2 rounded-circle" eager></v-img>
							<span class="ctym-midblue--text bullet-text font-weight-medium text-body-1 mr-3" v-text="item.text"></span>
						</div>
					</div>

					<v-lazy v-model="bigTitleLazy" min-height="200" :options="{ threshold: 0.9 }">			
						<wheres-the-money-text></wheres-the-money-text>
					</v-lazy>

				</div>
			</v-lazy>
		</v-col>

		<v-col cols="12" md="4" class="pb-0">
			<v-lazy v-model="imageLazy" min-height="200" class="fill-height d-flex justify-center justify-md-start align-md-end" :options="{ threshold: .8 }">
				<div class="px-10 px-md-0" style="max-width: 100vw;">
					<v-img :src="manImage" alt="A Smiling man" class="my-responsive-img" eager></v-img>
				</div>
			</v-lazy>
		</v-col>
		
	</v-row>
</template>

<script>
import coinImage from '@/assets/media/imgs/ctym_coin_image.png';
import manImage from '@/assets/media/imgs/ctym_man.png';

import WheresTheMoneyText from '@/components/landing_pages/compass_to_your_money/WheresTheMoneyText.vue';

export default {
	components: {
		WheresTheMoneyText,
	},
	data: () => ({
		coinImage,
		manImage,
		textLazy: false,
		bigTitleLazy: false,
		imageLazy: false,
		bullets: [
			{ text: 'במעבר בין מקומות עבודה יתכן שנשארו כספים שלכם בחיסכון פנסיוני שפתח עבורכם המעסיק הקודם' },
			{ text: 'עברתם דירה ולא עדכנתם את כל חברות הביטוח ובתי ההשקעות על הכתובת החדשה שלכם, הפסקתם לקבל מכתבים הביתה והכסף נשכח' },
			{ text: 'רפורמות ומיזוגים למיניהם בשוק הפיננסי גרמו לחסכונות של ישראלים לעבור מגוף אחד לאחר' },
		]
	}),
}
</script>

<style scoped>

	.small-title-gradient {
		background: radial-gradient(circle, var(--v-ctym-lightblue-base) 0%, var(--v-ctym-blue-base) 100%);
		background-clip: text;
		-webkit-background-clip: text;
  		-webkit-text-fill-color: transparent;
	}

	.small-size-coin {
		max-width: 15px;
		max-height: 15px;
	}

	.bullet-text {
		white-space: normal !important;
		max-width: 700px;
	}

</style>