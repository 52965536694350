<template>
	<div class="d-flex flex-column mt-15 mt-md-10 align-self-center align-self-md-start my-wrapper-div">
		<span class="my-so-where">אז איפה</span>
		<div class="my-inner-div">
			<span class="my-the-money-header">הכסף</span>
			<span class="my-question-mark">?</span>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style scoped>

	.my-wrapper-div {
		margin-right: 8em;
	}

	.my-inner-div {
		position: relative;
	}

	.my-so-where {
		color: var(--v-ctym-blue-base);
		font-size: 4em;
		font-weight: 600;
		margin-bottom: -1.35em;
		margin-right: 0.15em;
	}

	.my-the-money-header {
		color: var(--v-ctym-blue-base);
		font-size: 8em;
		font-weight: 700;
	}

	.my-question-mark {
		color: var(--v-ctym-lightblue-base);
		font-size: 12em;
		font-weight: 600;
		position: absolute;
		bottom: -20px;
		left: -100px;
	}

	@media screen and (max-width: 1220px) {

		.my-wrapper-div {
			margin-right: 2em;
		}
    
	}

	@media screen and (max-width: 960px) {

		.my-wrapper-div {
			margin-right: -4em;
		}
        
		.my-so-where {
			font-size: 3em;
			margin-bottom: -1.35em;
			margin-right: 0.15em;
		}

		.my-the-money-header {
			font-size: 6em;
		}

		.my-question-mark {
			font-size: 8em;
			bottom: -20px;
			left: -70px;
		}

	}

</style>